import Page from "../components/page";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";

const VormingPage = ({ data }) => 
  <Page>
    <SEO title='Vorming' />
    <div className='container px-8 mb-12 lg:mb-24 mt-4 lg:mt-16'>
      <div className='flex flex-col md:flex-row items-center md:mb-8 lg:mb-12'>
        <div className='md:w-1/2 md:pr-3 lg:pr-8'>
          <h2 className='title mb-6'>Workshops</h2>
          <div className='wysiwyg'>
            <p>
              Vanuit de expertise van de verschillende medewerkers worden er
              regelmatig workshops en groepssessies aangeboden in het
              Praktijkhuis. Neem contact met ons op indien u interesse heeft.
            </p>
            <p>
              <em>
                Aankondigingen, informatie over deelname en inschrijving vindt u
                hier binnenkort terug.
              </em>
            </p>
          </div>
        </div>
        <div className='md:w-1/2 md:pl-3 lg:pl-5'>
          <img
            className='block mb-8 w-full'
            loading='lazy'
            src={data.imageWorkshop.file.url}
            alt={data.imageWorkshop.title}
            title={data.imageWorkshop.title}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row-reverse items-center md:mb-8 lg:mb-12'>
        <div className='md:w-1/2 md:pl-3 lg:pl-8'>
          <h2 className='title mb-6'>Verhuur opleidingslokaal</h2>
          <div className='wysiwyg'>
            <p>
              Het opleidingslokaal kan ook gehuurd worden door externen. Het
              opleidingslokaal is een grote open ruimte met veel lichtinval en
              biedt plaats aan onge- veer 20 personen. Er kan aan een tafel
              gewerkt worden, in theateropstelling of bij lichaams- werk kunnen
              15 personen op matjes zitten. Er is een keuken ter beschikking.
            </p>
            <p>
              Voor meer info over mogelijkheden, beschikbaarheid en prijs kan je
              contact opnemen met{" "}
              <a href='mailto:katlijn@integraal.be'>Katlijn Willems</a>.
            </p>
          </div>
        </div>
        <div className='md:w-1/2 md:pr-3 lg:pr-5'>
          <img
            className='block mb-8 w-full'
            loading='lazy'
            src={data.imageVerhuur.file.url}
            alt={data.imageVerhuur.title}
            title={data.imageVerhuur.title}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row items-center'>
        <div className='md:w-1/2 md:pr-3 lg:pr-8'>
          <h2 className='title mb-6'>Opleidingen in service</h2>
          <div className='wysiwyg'>
            <p>
              Vzw Integraal biedt reeds 20 jaar opleidingen aan professionelen
              en vrijwilligers in de gezondheidszorg. Wij werken met veel
              enthousiasme met artsen, verpleegkundigen, psychologen,
              maatschappelijk werkers, vrijwilligers die zich willen bijscholen
              in hun vak. Deze opleidingen gaan zowel over communicatie, omgaan
              met patiënten en hun familie alsook over thema’s als pijn- en
              symptoomcontrole in de palliatieve zorg, rouw- en
              verliesverwerking,...
            </p>
            <p>
              De vaste opleiders en coördinatoren van het freelance team voor
              deze thema’s zijn Dr Peter Demeulenaere, huisarts en diensthoofd
              palliatieve eenheid Sint Camillus en Katlijn Willems, trainer
              systeemtherapeut.
            </p>
            <p>
              Organiseer je een opleiding of zoek je een spreker voor je
              organisatie dan kan je ons telefonisch of per mail contacteren.
              Wij zoeken samen met u dan naar een gepast aanbod.
            </p>
            <p>
              Katlijn Willems |{" "}
              <a href='mailto:katlijn@integraal.be'>katlijn@integraal.be</a>{" "}
              <br />
              Vzw Integraal Maatschappelijke zetel: Waterloostraat 63 2600
              Berchem
            </p>
          </div>
        </div>
        <div className='md:w-1/2 md:pl-3 lg:pl-5'>
          <img
            className='block mb-8 w-full'
            loading='lazy'
            src={data.imageService.file.url}
            alt={data.imageService.title}
            title={data.imageService.title}
          />
        </div>
      </div>
    </div>
  </Page>;


VormingPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default VormingPage;

export const pageQuery = graphql`
  query {
    imageVerhuur: contentfulAsset(
      contentful_id: { eq: "68SIPfKUeWvNtUqLF6mEc7" }
    ) {
      title
      file {
        url
      }
    }
    imageWorkshop: contentfulAsset(
      contentful_id: { eq: "5RNdFYShv8FyRKDOPO74Ac" }
    ) {
      title
      file {
        url
      }
    }
    imageService: contentfulAsset(
      contentful_id: { eq: "7nMWz5T98BlStp1B77nSE9" }
    ) {
      title
      file {
        url
      }
    }
  }
`;
